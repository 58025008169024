:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._display-flex{display:flex;}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._paddingRight-1481558245{padding-right:var(--space-6);}
:root ._paddingLeft-1481558245{padding-left:var(--space-6);}
:root ._paddingTop-1481558276{padding-top:var(--space-5);}
:root ._paddingBottom-1481558276{padding-bottom:var(--space-5);}
:root ._borderTopLeftRadius-80px{border-top-left-radius:80px;}
:root ._borderTopRightRadius-80px{border-top-right-radius:80px;}
:root ._borderBottomRightRadius-80px{border-bottom-right-radius:80px;}
:root ._borderBottomLeftRadius-80px{border-bottom-left-radius:80px;}
:root ._backgroundColor-1218797898{background-color:var(--backgroundPrimary);}
:root ._backgroundColor-1726194350{background-color:transparent;}