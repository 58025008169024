:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._width-260px{width:260px;}
:root ._paddingTop-base{padding-top:base;}
:root ._paddingRight-1481558245{padding-right:var(--space-6);}
:root ._paddingBottom-base{padding-bottom:base;}
:root ._paddingLeft-1481558245{padding-left:var(--space-6);}
:root ._marginTop-1481558245{margin-top:var(--space-6);}
:root ._flexDirection-row{flex-direction:row;}
:root ._marginLeft-1481558307{margin-left:var(--space-4);}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-14px{font-size:14px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._fontWeight-400{font-weight:400;}