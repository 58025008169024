:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._width-160px{width:160px;}
:root ._borderTopLeftRadius-36px{border-top-left-radius:36px;}
:root ._borderTopRightRadius-36px{border-top-right-radius:36px;}
:root ._borderBottomRightRadius-36px{border-bottom-right-radius:36px;}
:root ._borderBottomLeftRadius-36px{border-bottom-left-radius:36px;}
:root ._opacity-0d0t546{opacity:0.5;}
:root ._opacity-1{opacity:1;}
:root ._paddingTop-1481558183{padding-top:var(--space-8);}
:root ._paddingRight-1481558183{padding-right:var(--space-8);}
:root ._paddingBottom-1481558183{padding-bottom:var(--space-8);}
:root ._paddingLeft-1481558183{padding-left:var(--space-8);}