:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-18px{font-size:18px;}
:root ._lineHeight-28px{line-height:28px;}
:root ._fontWeight-700{font-weight:700;}
:root ._fontSize-24px{font-size:24px;}
:root ._lineHeight-32px{line-height:32px;}
:root ._fontSize-32px{font-size:32px;}
:root ._lineHeight-42px{line-height:42px;}
:root ._letterSpacing--0d0t5px46{letter-spacing:-0.5px;}
:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._marginTop-small{margin-top:small;}
:root ._marginBottom-small{margin-bottom:small;}
:root ._backgroundColor-1726194350{background-color:transparent;}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}
:root ._color-1963383771{color:var(--backgroundNeutralHover);}
:root ._fontSize-14px{font-size:14px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._fontWeight-600{font-weight:600;}