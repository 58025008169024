:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._display-flex{display:flex;}
:root ._position-relative{position:relative;}
:root ._alignItems-center{align-items:center;}
:root ._gap-1481558276{gap:var(--space-5);}
:root ._alignItems-stretch{align-items:stretch;}
:root ._width-10037{width:100%;}
:root ._borderTopLeftRadius-1307609936{border-top-left-radius:var(--radius-3);}
:root ._borderTopRightRadius-1307609936{border-top-right-radius:var(--radius-3);}
:root ._borderBottomRightRadius-1307609936{border-bottom-right-radius:var(--radius-3);}
:root ._borderBottomLeftRadius-1307609936{border-bottom-left-radius:var(--radius-3);}
:root ._height-320px{height:320px;}
:root ._height-180px{height:180px;}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-32px{font-size:32px;}
:root ._lineHeight-42px{line-height:42px;}
:root ._fontWeight-700{font-weight:700;}
:root ._letterSpacing--0d0t5px46{letter-spacing:-0.5px;}
:root ._marginTop-1481558369{margin-top:var(--space-2);}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}