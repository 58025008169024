:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._paddingTop-1481558245{padding-top:var(--space-6);}
:root ._paddingBottom-1481558245{padding-bottom:var(--space-6);}
@media (max-width: 759px){:root:root:root:root ._paddingTop-_sm_1481558276{padding-top:var(--space-5);}}
@media (max-width: 759px){:root:root:root:root ._paddingRight-_sm_1481558276{padding-right:var(--space-5);}}
@media (max-width: 759px){:root:root:root:root ._paddingBottom-_sm_1481558276{padding-bottom:var(--space-5);}}
@media (max-width: 759px){:root:root:root:root ._paddingLeft-_sm_1481558276{padding-left:var(--space-5);}}
:root ._paddingTop-1481558183{padding-top:var(--space-8);}
:root ._paddingRight-1481558183{padding-right:var(--space-8);}
:root ._paddingBottom-1481558183{padding-bottom:var(--space-8);}
:root ._paddingLeft-1481558183{padding-left:var(--space-8);}
:root ._borderTopLeftRadius-24px{border-top-left-radius:24px;}
:root ._borderTopRightRadius-24px{border-top-right-radius:24px;}
:root ._borderBottomRightRadius-24px{border-bottom-right-radius:24px;}
:root ._borderBottomLeftRadius-24px{border-bottom-left-radius:24px;}
:root ._backgroundColor-06491635{background-color:#064916;}
:root ._alignContent-441309761{align-content:space-between;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._maxWidth-1280px{max-width:1280px;}
:root ._alignSelf-center{align-self:center;}
:root ._width-10037{width:100%;}
@media (min-width: 760px){:root:root:root:root:root ._flexDirection-_gtSm_row{flex-direction:row;}}
@media (max-width: 759px){:root:root:root:root ._flexDirection-_sm_column{flex-direction:column;}}
@media (max-width: 759px){:root:root:root:root ._justifyContent-_sm_center{justify-content:center;}}
:root ._flexShrink-1{flex-shrink:1;}
:root ._flexGrow-1{flex-grow:1;}
:root ._marginRight-1481558245{margin-right:var(--space-6);}
:root ._justifyContent-center{justify-content:center;}
@media (max-width: 759px){:root:root:root:root ._marginRight-_sm_1481558400{margin-right:var(--space-1);}}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-1481558276{margin-bottom:var(--space-5);}
:root ._marginLeft-0px{margin-left:0px;}
:root ._textAlign-left{text-align:left;}
:root ._fontSize-32px{font-size:32px;}
:root ._lineHeight-42px{line-height:42px;}
:root ._fontWeight-700{font-weight:700;}
:root ._letterSpacing--0d0t5px46{letter-spacing:-0.5px;}
:root ._color-423328895{color:var(--contentInversePrimary);}
@media (max-width: 759px){:root:root:root:root ._textAlign-_sm_center{text-align:center;}}
@media (max-width: 759px){:root:root:root:root ._marginBottom-_sm_1481558400{margin-bottom:var(--space-1);}}
:root ._alignContent-center{align-content:center;}
:root ._marginTop-1481558245{margin-top:var(--space-6);}
:root ._marginBottom-1481558245{margin-bottom:var(--space-6);}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._textAlign-center{text-align:center;}
:root ._alignItems-center{align-items:center;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
:root ._paddingLeft-1481558276{padding-left:var(--space-5);}