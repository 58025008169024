:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._gap-1481558245{gap:var(--space-6);}
:root ._display-flex{display:flex;}
:root ._width-10037{width:100%;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-center{justify-content:center;}
@media (min-width: 760px){:root:root:root:root:root ._flexDirection-_gtSm_1448970769{flex-direction:row-reverse;}}
@media (min-width: 760px){:root:root:root:root:root ._flexDirection-_gtSm_row{flex-direction:row;}}
:root ._alignItems-stretch{align-items:stretch;}
:root ._maxWidth-4437{max-width:44%;}
:root ._width-48px{width:48px;}
:root ._height-48px{height:48px;}
:root ._backgroundColor-43814371{background-color:var(--color-56);}
:root ._borderTopLeftRadius-24px{border-top-left-radius:24px;}
:root ._borderTopRightRadius-24px{border-top-right-radius:24px;}
:root ._borderBottomRightRadius-24px{border-bottom-right-radius:24px;}
:root ._borderBottomLeftRadius-24px{border-bottom-left-radius:24px;}
@media (min-width: 760px){:root:root:root:root:root ._maxWidth-_gtSm_4437{max-width:44%;}}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-18px{font-size:18px;}
:root ._lineHeight-28px{line-height:28px;}
:root ._fontWeight-700{font-weight:700;}
:root ._textAlign-center{text-align:center;}
@media (min-width: 760px){:root:root:root:root:root ._textAlign-_gtSm_right{text-align:right;}}
@media (min-width: 760px){:root:root:root:root:root ._textAlign-_gtSm_left{text-align:left;}}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}