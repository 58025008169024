:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._display-flex{display:flex;}
:root ._justifyContent-center{justify-content:center;}
:root ._alignItems-center{align-items:center;}
:root ._paddingRight-1481558276{padding-right:var(--space-5);}
:root ._paddingLeft-1481558276{padding-left:var(--space-5);}
:root ._paddingTop-1481558245{padding-top:var(--space-6);}
:root ._paddingBottom-1481558245{padding-bottom:var(--space-6);}
:root ._width-10037{width:100%;}
:root ._zIndex-10{z-index:10;}
:root ._height-96px{height:96px;}
:root ._height-72px{height:72px;}
:root ._backgroundColor-43815239{background-color:var(--color-49);}
:root ._backgroundColor-primaryB{background-color:primaryB;}
:root ._borderBottomColor-1726194350{border-bottom-color:transparent;}
:root ._borderBottomWidth-0px{border-bottom-width:0px;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderBottomColor-1371260885{border-bottom-color:var(--borderDefault);}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}