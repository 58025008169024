:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._paddingTop-base{padding-top:base;}
:root ._paddingRight-base{padding-right:base;}
:root ._paddingBottom-base{padding-bottom:base;}
:root ._paddingLeft-base{padding-left:base;}