:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._position-absolute{position:absolute;}
:root ._top-56px{top:56px;}
:root ._right-12px{right:12px;}
:root ._right-0px{right:0px;}
:root ._position-relative{position:relative;}
:root ._top-0px{top:0px;}
:root ._transition-d0t1003939520{transition:all 0.5s ease-in-out;}
:root ._transform-1529303649{transform:translateY(20%);}
:root ._paddingRight-1481558307{padding-right:var(--space-4);}
:root ._justifyContent-center{justify-content:center;}
:root ._zIndex-20{z-index:20;}
:root ._width-10037{width:100%;}
:root ._zIndex-10{z-index:10;}
:root ._paddingLeft-1481558214{padding-left:var(--space-7);}
:root ._paddingRight-1481558276{padding-right:var(--space-5);}
:root ._paddingTop-1481558245{padding-top:var(--space-6);}
:root ._paddingBottom-1481558245{padding-bottom:var(--space-6);}
:root ._backgroundColor-43815239{background-color:var(--color-49);}
:root ._backgroundColor-primaryB{background-color:primaryB;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._height-76px{height:76px;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._columnGap-1481558338{column-gap:var(--space-3);}