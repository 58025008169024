:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._width-260px{width:260px;}
:root ._paddingTop-1481558338{padding-top:var(--space-3);}
:root ._paddingBottom-1481558338{padding-bottom:var(--space-3);}
:root ._backgroundColor-1218797898{background-color:var(--backgroundPrimary);}
:root ._borderTopLeftRadius-1307609998{border-top-left-radius:var(--radius-5);}
:root ._borderTopRightRadius-1307609998{border-top-right-radius:var(--radius-5);}
:root ._borderBottomRightRadius-1307609998{border-bottom-right-radius:var(--radius-5);}
:root ._borderBottomLeftRadius-1307609998{border-bottom-left-radius:var(--radius-5);}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderBottomColor-1371260885{border-bottom-color:var(--borderDefault);}
:root ._paddingBottom-1481558276{padding-bottom:var(--space-5);}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._paddingRight-1481558245{padding-right:var(--space-6);}
:root ._paddingLeft-1481558245{padding-left:var(--space-6);}
:root ._cursor-pointer{cursor:pointer;}
@media (hover) {:root  ._backgroundColor-0hover-655269800:hover{background-color:var(--backgroundTertiary) !important;}}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-700{font-weight:700;}
:root ._paddingTop-1481558276{padding-top:var(--space-5);}
:root ._paddingTop-0px{padding-top:0px;}
:root ._fontWeight-400{font-weight:400;}
:root ._alignItems-center{align-items:center;}
:root ._rowGap-1481558276{row-gap:var(--space-5);}
:root ._fontSize-14px{font-size:14px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._color-43814557{color:var(--color-50);}