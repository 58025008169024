:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._maxWidth-952px{max-width:952px;}
:root ._borderTopLeftRadius-80px{border-top-left-radius:80px;}
:root ._borderTopRightRadius-80px{border-top-right-radius:80px;}
:root ._borderBottomRightRadius-80px{border-bottom-right-radius:80px;}
:root ._borderBottomLeftRadius-80px{border-bottom-left-radius:80px;}
:root ._backgroundColor-1218797898{background-color:var(--backgroundPrimary);}
:root ._display-flex{display:flex;}
:root ._borderTopStyle-solid{border-top-style:solid;}
:root ._borderRightStyle-solid{border-right-style:solid;}
:root ._borderBottomStyle-solid{border-bottom-style:solid;}
:root ._borderLeftStyle-solid{border-left-style:solid;}
:root ._borderTopWidth-1px{border-top-width:1px;}
:root ._borderRightWidth-1px{border-right-width:1px;}
:root ._borderBottomWidth-1px{border-bottom-width:1px;}
:root ._borderLeftWidth-1px{border-left-width:1px;}
:root ._borderTopColor-1371260885{border-top-color:var(--borderDefault);}
:root ._borderRightColor-1371260885{border-right-color:var(--borderDefault);}
:root ._borderBottomColor-1371260885{border-bottom-color:var(--borderDefault);}
:root ._borderLeftColor-1371260885{border-left-color:var(--borderDefault);}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._cursor-pointer{cursor:pointer;}
:root ._height-52px{height:52px;}
:root ._bottom-1481558245{bottom:var(--space-6);}
:root ._zIndex-1{z-index:1;}
@media (min-width: 1040px){:root:root:root:root:root:root:root ._height-_gtMd_auto{height:auto;}}
@media (min-width: 1040px){:root:root:root:root:root:root:root ._bottom-_gtMd_0px{bottom:0px;}}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._maxWidth-212px{max-width:212px;}
:root ._paddingTop-1481558276{padding-top:var(--space-5);}
:root ._paddingRight-1481558276{padding-right:var(--space-5);}
:root ._paddingBottom-1481558276{padding-bottom:var(--space-5);}
:root ._paddingLeft-1481558276{padding-left:var(--space-5);}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-14px{font-size:14px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._fontWeight-600{font-weight:600;}
:root ._overflowX-hidden{overflow-x:hidden;}
:root ._overflowY-hidden{overflow-y:hidden;}
:root ._textOverflow-ellipsis{text-overflow:ellipsis;}
:root ._whiteSpace-nowrap{white-space:nowrap;}
:root ._color-302081389{color:var(--contentSecondary);}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._alignSelf-center{align-self:center;}