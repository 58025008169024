:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._gap-1481558276{gap:var(--space-5);}
:root ._paddingTop-1481558245{padding-top:var(--space-6);}
@media (min-width: 1040px){:root:root:root:root:root:root:root ._paddingTop-_gtMd_1481558400{padding-top:var(--space-1);}}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._fontSize-12px{font-size:12px;}
:root ._lineHeight-20px{line-height:20px;}
:root ._fontWeight-600{font-weight:600;}
:root ._color-302081389{color:var(--contentSecondary);}
:root ._cursor-pointer{cursor:pointer;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}
:root ._fontSize-14px{font-size:14px;}