:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-18px{font-size:18px;}
:root ._lineHeight-28px{line-height:28px;}
:root ._fontWeight-700{font-weight:700;}
:root ._display-flex{display:flex;}
:root ._justifyContent-center{justify-content:center;}
:root ._justifyContent-flex-start{justify-content:flex-start;}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}
:root ._textAlign-center{text-align:center;}
:root ._textAlign-auto{text-align:auto;}