:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._display-flex{display:flex;}
:root ._flexDirection-row{flex-direction:row;}
:root ._justifyContent-441309761{justify-content:space-between;}
:root ._minHeight-640px{min-height:640px;}
:root ._flexWrap-wrap{flex-wrap:wrap;}
:root ._position-relative{position:relative;}
:root ._alignItems-center{align-items:center;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._width-420px{width:420px;}
:root ._minHeight-432px{min-height:432px;}
:root ._backgroundColor-1218797898{background-color:var(--backgroundPrimary);}
:root ._borderTopLeftRadius-1307609967{border-top-left-radius:var(--radius-4);}
:root ._borderTopRightRadius-1307609967{border-top-right-radius:var(--radius-4);}
:root ._borderBottomRightRadius-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._borderBottomLeftRadius-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._zIndex-1{z-index:1;}
:root ._paddingTop-1481558214{padding-top:var(--space-7);}
:root ._paddingRight-1481558214{padding-right:var(--space-7);}
:root ._paddingBottom-1481558214{padding-bottom:var(--space-7);}
:root ._paddingLeft-1481558214{padding-left:var(--space-7);}
:root ._gap-1481558276{gap:var(--space-5);}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-32px{font-size:32px;}
:root ._lineHeight-42px{line-height:42px;}
:root ._fontWeight-700{font-weight:700;}
:root ._letterSpacing--0d0t5px46{letter-spacing:-0.5px;}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}
:root ._minHeight-0px{min-height:0px;}
:root ._width-8037{width:80%;}
:root ._height-560px{height:560px;}
:root ._position-absolute{position:absolute;}
:root ._right-0px{right:0px;}