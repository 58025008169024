:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._fontSize-18px{font-size:18px;}
:root ._lineHeight-28px{line-height:28px;}
:root ._fontWeight-600{font-weight:600;}
:root ._color-43814526{color:var(--color-51);}