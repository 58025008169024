:root ._display-flex{display:flex;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._borderTopLeftRadius-80px{border-top-left-radius:80px;}
:root ._borderTopRightRadius-80px{border-top-right-radius:80px;}
:root ._borderBottomRightRadius-80px{border-bottom-right-radius:80px;}
:root ._borderBottomLeftRadius-80px{border-bottom-left-radius:80px;}
:root ._alignItems-flex-start{align-items:flex-start;}
:root ._flexDirection-row{flex-direction:row;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-center{justify-content:center;}