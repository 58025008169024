:root ._display-flex{display:flex;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._gap-1481558338{gap:var(--space-3);}
:root ._flexGrow-1{flex-grow:1;}
:root ._alignItems-center{align-items:center;}
:root ._justifyContent-center{justify-content:center;}
:root ._width-10037{width:100%;}
@media (min-width: 760px){:root:root:root:root:root ._gap-_gtSm_1481558276{gap:var(--space-5);}}
@media (min-width: 760px){:root:root:root:root:root ._flexDirection-_gtSm_row{flex-direction:row;}}
@media (min-width: 760px){:root:root:root:root:root ._width-_gtSm_33d0t331463{width:33.33%;}}
:root ._flexWrap-wrap{flex-wrap:wrap;}
@media (min-width: 760px){:root:root:root:root:root ._alignItems-_gtSm_flex-start{align-items:flex-start;}}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-18px{font-size:18px;}
:root ._lineHeight-28px{line-height:28px;}
:root ._fontWeight-700{font-weight:700;}
:root ._fontSize-16px{font-size:16px;}
:root ._lineHeight-24px{line-height:24px;}
:root ._fontWeight-400{font-weight:400;}
:root ._textAlign-center{text-align:center;}
@media (min-width: 760px){:root:root:root:root:root ._textAlign-_gtSm_left{text-align:left;}}