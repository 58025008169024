:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._gap-1316330114{gap:var(--space-10);}
:root ._width-10037{width:100%;}
:root ._fontFamily-299667014{font-family:var(--f-fa);}
:root ._display-inline{display:inline;}
:root ._wordWrap-break-word{word-wrap:break-word;}
:root ._whiteSpace-pre-wrap{white-space:pre-wrap;}
:root ._marginTop-0px{margin-top:0px;}
:root ._marginRight-0px{margin-right:0px;}
:root ._marginBottom-0px{margin-bottom:0px;}
:root ._marginLeft-0px{margin-left:0px;}
:root ._color-19330913{color:var(--contentPrimary);}
:root ._fontSize-52px{font-size:52px;}
:root ._lineHeight-62px{line-height:62px;}
:root ._fontWeight-700{font-weight:700;}
:root ._letterSpacing--0d0t5px46{letter-spacing:-0.5px;}
:root ._overflowX-scroll{overflow-x:scroll;}
:root ._overflowY-scroll{overflow-y:scroll;}