:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-1{flex-shrink:1;}
:root ._gap-1481558183{gap:var(--space-8);}
:root ._flexGrow-1{flex-grow:1;}
:root ._display-flex{display:flex;}
@media (min-width: 760px){:root:root:root:root:root ._gap-_gtSm_1481558245{gap:var(--space-6);}}
@media (min-width: 760px){:root:root:root:root:root ._flexDirection-_gtSm_row{flex-direction:row;}}