:root ._display-flex{display:flex;}
:root ._alignItems-stretch{align-items:stretch;}
:root ._flexDirection-column{flex-direction:column;}
:root ._flexBasis-auto{flex-basis:auto;}
:root ._boxSizing-border-box{box-sizing:border-box;}
:root ._position-relative{position:relative;}
:root ._minHeight-0px{min-height:0px;}
:root ._minWidth-0px{min-width:0px;}
:root ._flexShrink-0{flex-shrink:0;}
:root ._height-40px{height:40px;}
:root ._width-2px{width:2px;}
:root ._borderLeftWidth-2px{border-left-width:2px;}
:root ._borderLeftColor-43814464{border-left-color:var(--color-53);}
:root ._borderTopStyle-dashed{border-top-style:dashed;}
:root ._borderRightStyle-dashed{border-right-style:dashed;}
:root ._borderBottomStyle-dashed{border-bottom-style:dashed;}
:root ._borderLeftStyle-dashed{border-left-style:dashed;}